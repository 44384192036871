export default {
  en: {
    timezone: {
      timeZone: "Europe/Berlin",
      timeZoneName: "short"
    },
    short: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      timeZone: "Europe/Berlin"
    },
    dateonly: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      timeZone: "Europe/Berlin"
    },
    datetime: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      pattern: "{hour}:{minute}",
      timeZone: "Europe/Berlin"
    },
    datetimepad: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      pattern: "{hour}:{minute}",
      timeZone: "Europe/Berlin",
    },
    datetimeZ: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      pattern: "{hour}:{minute}",
      timeZone: "Europe/Berlin",
      timeZoneName: "short"
    },
    datetimewd: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
      pattern: "{hour}:{minute}",
      timeZone: "Europe/Berlin",
    },
    datetimefull: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      pattern: "{hour}:{minute}:{second}",
      timeZone: "Europe/Berlin"
    },
    timeonly: {
      hour: "numeric",
      minute: "numeric",
      timeZone: "Europe/Berlin"
    },
    timeonlyZ: {
      hour: "numeric",
      minute: "numeric",
      timeZone: "Europe/Berlin",
      timeZoneName: "short"
    },
    timeonlyfull: {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "Europe/Berlin"
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
      timeZone: "Europe/Berlin"
    },
  },
  de: {
    timezone: {
      timeZone: "Europe/Berlin",
      timeZoneName: "short"
    },
    short: {
      year: "numeric",
      month: "short",
      day: "2-digit",
      timeZone: "Europe/Berlin"
    },
    dateonly: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      timeZone: "Europe/Berlin"
    },
    datetime: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      pattern: "{hour}:{minute}",
      timeZone: "Europe/Berlin",
    },
    datetimepad: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      pattern: "{hour}:{minute}",
      timeZone: "Europe/Berlin",
    },
    datetimeZ: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      pattern: "{hour}:{minute}",
      timeZone: "Europe/Berlin",
      timeZoneName: "short"
    },
    datetimewd: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      weekday: "short",
      hour: "2-digit",
      minute: "2-digit",
      pattern: "{hour}:{minute}",
      timeZone: "Europe/Berlin"
    },
    datetimefull: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      pattern: "{hour}:{minute}:{second}",
      timeZone: "Europe/Berlin"
    },
    timeonly: {
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "Europe/Berlin"
    },
    timeonlyZ: {
      hour: "numeric",
      minute: "numeric",
      timeZone: "Europe/Berlin",
      timeZoneName: "short"
    },
    timeonlyfull: {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "Europe/Berlin"
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
      timeZone: "Europe/Berlin"
    },
  },
};
