{
  "common": {
    "labels": {
      "additionalactions": "Additional actions",
      "merge": "Merge"
    },
    "terms": {
      "addperson": "Add person",
      "execute": "Execute",
      "invalidvalue": "Invalid specification",
      "object": "Object"
    }
  },
  "components": {
    "campuseventselect": {
      "campuseventhint": "A linked campus event enables the ILIAS export, the display of information from the event and an automatic reference to the registration procedure in the public VVZ",
      "selectevent": "Select event"
    },
    "iliasexportsuccessdialog": {
      "intro": "Your participants have been successfully transferred to ILIAS according to your settings. You can view the member lists of the respective courses via the course links in the \"Export options\" area.",
      "title": "ILIAS export successful"
    },
    "localuserselect": {
      "hint": "Note: You can only search for people who have logged in at least once in the portal."
    },
    "usageprogress": {
      "acceptances": "Accepted",
      "offerings": "Offers"
    }
  },
  "dialogs": {
    "assignGroup": {
      "message": "Are you sure you want to assign the {name} group to the {count} selected events? ",
      "title": "Assign group"
    },
    "assignGroupTemplate": {
      "message": "Do you really want to assign the {name} group to the {count} selected templates?"
    },
    "deleteGroup": {
      "message": "Are you sure you want to delete the group {name}?<br />No registration procedures will be deleted."
    },
    "deleteProcedures": {
      "message": "Only registration procedures that are not published and have no registrations can be deleted from the list.<br /><br />Of the selected procedures, <strong>{count} of {total} procedures</strong> meet these requirements.< \nbr /><br />Do you want to delete them now?",
      "novalidprocedures": "To protect against accidental deletion, only registration procedures that are not published and do not have any registrations can be deleted via the list. \n<br /><br />Currently you have not selected any acceptable procedures. \nPlease delete procedures with registrations directly from the procedure."
    },
    "deleteTemplates": {
      "message": "Do you really want to delete the selected {count} templates?"
    },
    "deleteTopic": {
      "message": "Do you really want to delete the topic/appointment {name}?<br />All ratings and assignments will be lost."
    },
    "removeGroup": {
      "message": "Do you really want to remove the group {name} from the {count} selected registration procedures?",
      "title": "Remove group"
    },
    "removeGroupTemplate": {
      "message": "Do you really want to remove the group {name} from the {count} selected templates?"
    },
    "renewaccessid": {
      "message": "Are you sure you want to regenerate the private access link? \nThe old address is no longer valid."
    },
    "toggleVisibility": {
      "message": "Do you want to change the visibility of all selected events to <strong>{visibility}</strong>?"
    }
  },
  "fields": {
    "activity": {
      "type": {
        "values": {
          "BoostActivate": "Boost for registration activated",
          "BoostDeactivate": "Boost is deactivated",
          "FcfsAccept": "FCFS: Accepted",
          "Lottery": "Lottery performed",
          "LotteryAccept": "Accepted by lottery",
          "ManagerRegistrationMerge": "{targetUser} was added to the study group of {description}.",
          "ManagerRegistrationSplit": "{targetUser} has been removed from the study group of {description}.",
          "ManagerSignupAdd": "{targetUser} was registered by administrator.",
          "ProcedureAccessChanged": "Access settings changed",
          "ProcedureAdd": "Registration procedure created",
          "ProcedureEdit": "Settings changed",
          "ProcedureGroupAssigned": "The {description} group has been assigned",
          "ProcedureGroupRemoved": "The {description} group has been removed",
          "ProcedureManagerAdded": "Granted access rights to {targetUser}",
          "ProcedureManagerDeleted": "{targetUser}'s access rights revoked",
          "ProcedureTopicAdded": "Added topic {description} ",
          "ProcedureTopicDeleted": "Deleted topic {description}",
          "ProcedureTopicOrderChanged": "Changed topic order",
          "ProcedureTopicUpdated": "Topic {description} updated",
          "RenewProcedureAccessId": "Private access link updated",
          "SignupAdd": "New registration",
          "SignupAddTopic": "New registration (selected: {description})",
          "SignupConfirmed": "Participation has been confirmed",
          "SignupEdit": "Registration changed",
          "SignupJoin": "Joined study group",
          "SignupRemove": "Deregistration performed",
          "SportRankingLottery": "Term ranking allocation procedure executed",
          "SportRankingLotteryAccept": "Accepted by term ranking allocation procedure",
          "StateChange": "Registration status of applications changed",
          "StateDeclineUnconfirmed": "The offer to {targetUser} was automatically rejected after the deadline had been exceeded.",
          "TemplateAdd": "New template created"
        }
      }
    },
    "group": {
      "allowedBoostCount": {
        "label": "Boosts per term"
      },
      "allowedConfirmationCount": {
        "label": "Max. Confirmations per term"
      },
      "allowedRegistrationCount": {
        "label": "Max. registrations per term"
      },
      "name": {
        "label": "Name"
      },
      "procedureCount": {
        "label": "Linked Procedures"
      },
      "shortName": {
        "invalid": "The description may only consist of letters and numbers"
      },
      "shortname": {
        "label": "Short name"
      },
      "visibility": {
        "label": "Visibility",
        "values": {
          "0": {
            "description": "The group is publicly visible and can be used as a filter in the offer list.",
            "label": "Public"
          },
          "1": {
            "description": "The group is not listed in the offer list, but is visible in the details when registration restrictions exist.",
            "label": "Show when restrictions exist"
          },
          "2": {
            "description": "The group is visible only to the group managers, useful if you use the group only for permissions.",
            "label": "Hidden"
          }
        }
      }
    },
    "optimization": {
      "status": {
        "FEASIBLE": "Feasible 🤔",
        "INFEASIBLE": "No solution 😞",
        "OPTIMAL": "Optimal 🎉"
      }
    },
    "procedure": {
      "allocationType": {
        "abbr": {
          "FirstComeFirstServed": "FCFS",
          "Lottery": "Lottery",
          "Manual": "Manual",
          "Optimization": "Optimization",
          "SportRanking": "Term Ranking",
          "none": "none"
        },
        "descriptions": {
          "FirstComeFirstServed": "First Come First Served allocates places based on the time at which applications are received. \nIn order to avoid overbooking due to simultaneous registration, the system first creates all registrations with the status Open and then allocates available places in the order in the database. \nThis ensures that you are not suddenly confronted with more acceptances than available places.<br /><br />This procedure currently does not support the automatic assignment of topics or dates. \nHowever, you can manually assign topics or dates to the confirmations.<br /><br /><strong>Please note:</strong> First Come First Served disadvantages interested parties who are unable to register due to time or technical reasons (e.g. stay abroad, vacation \n, ...) considerably. \nPlease consider whether a different procedure (e.g. lottery) would not work just as well, especially in the case of high demand.",
          "Lottery": "The lottery procedure takes the time pressure out of the first come, first served procedure and allows interested parties to register in a relaxed manner within the registration period. \nAt the specified time, the places will be raffled among all registrations received.<br />If places remain or become vacant, the raffle will be repeated periodically in order to fill the free places from the waiting list or registrations received in the meantime until the end of the move-up has been reached. \nPeople from the waiting list (i.e. participants in a previous lottery procedure without confirmation) are given preference. \nManual intervention is not necessary.",
          "Manual": "You will find all the registrations received in the registration list for your procedure. \nThere, the topic ratings (if available) and information provided (if rquested) can be viewed either by adding the columns or the detailed view. \nYou can then use the status and topic dropdowns (view 1) or the status and topic buttons (view 2) to make a selection and decide whether the participant has made an offer to participate (must be confirmed) or accepted (no longer needs to be confirmed). \nwill get. \nThe waiting list status can also be used to inform potential followers, but they are not automatically moved up.<br /><br />By saving the changes, the status changes take effect and students are informed. \nIn the event of cancellation or late confirmation by students, all registered lecturers will be informed by e-mail about the space that has become vacant. \nThere is no automatic replacement.",
          "Optimization": "With the optimal distribution, all applicants must submit a rating for topics, dates or events when registering. \nAt the end of the registration period, you can then carry out the optimal distribution. \nLinear optimization is used here in order to distribute all (or if there is not enough space: as many as possible) registrations according to the respective preference on the available topics/dates.<br /><br /> This procedure tries to determine the overall satisfaction, which is the average \nSatisfaction with the appointment allocation of each individual participant.<br /><br />After the optimization you can see how the distribution and satisfaction looks like and you can make adjustments to the available places if necessary.",
          "SportRanking": "This procedure uses a ranking list (based on subject semester, standard period of study and a lot in the event of a tie) to allocate free places. \nPlaces are initially allocated through the ranking list, then through the waiting list and new registrations again through a ranking list calculation."
        },
        "explanations": {
          "FirstComeFirstServed": "The places will be assigned automatically according to the time of receipt.",
          "Lottery": "At the time of drawing lots, places will be drawn from all the registrations received.",
          "Manual": "The lecturer selects the participants.",
          "Optimization": "The places and assignments are optimally distributed based on the ratings given.",
          "SportRanking": "The places are assigned according to a ranking process based on the subject semester and the standard period of study."
        },
        "label": "Allocation procedure",
        "managerhints": {
          "FirstComeFirstServed": "The places here are allocated according to the time at which the application is received. \nThis is often associated with considerable disadvantages for applicants at popular events.",
          "Lottery": "At a time you specify, the available places will be raffled among all applicants. \nMoving up is then based on the first-come-first-served principle.",
          "Manual": "You can accept and decline in the participant list. \nYou can take into account the information provided during registration.",
          "Optimization": "All applicants are optimally distributed based on their preferences over a number of appointments, topics or events.",
          "SportRanking": "Creates a ranking list based on the current user's term and the standard period of study and allocates places according to this. \nIn the follow-up phase, the places will be assigned according to the ranking list and further places according to a lottery."
        },
        "recommendations": {
          "FirstComeFirstServed": "Events with a limited or unlimited number of places and medium demand, e.g. exam grade review.",
          "Lottery": "Events with limited space and often high demand, e.g. seminars or language courses.",
          "Manual": "Seminars or small events where you want to find the most suitable participants.",
          "Optimization": "Large events where a large number of dates or topics are available and you want to distribute them as optimally as possible, e.g. tutorials.",
          "SportRanking": "Courses in which students in higher semesters should be given preference."
        },
        "values": {
          "0": "Manual Selection",
          "1": "Optimal Distribution",
          "2": "Lottery",
          "3": "First Come First Served",
          "4": "Term-Ranking",
          "FirstComeFirstServed": "First Come First Served",
          "Lottery": "Lottery",
          "Manual": "Manual Selection",
          "Optimization": "Optimized distribution",
          "SportRanking": "Term-Ranking",
          "null": "not specified"
        }
      },
      "closingDate": "End of succession",
      "eventType": {
        "label": "Type of procedure",
        "values": {
          "BonusExam": "Bonus Exam",
          "Exam": "Examination",
          "Lecture": "Lecture",
          "Other": "Other",
          "Practical": "Practical",
          "Review": "Exam Review",
          "Seminar": "Seminar",
          "Tutorial": "Tutorial",
          "Workshop": "Day workshop"
        }
      },
      "firstDrawDate": "Next scheduled lottery",
      "questions": {
        "types": {
          "0": "Text field (single line)",
          "1": "Text field (multi-line)",
          "2": "Numeric value",
          "3": "File upload (PDF)",
          "4": "Selection (single)",
          "5": "Selection (multiple)"
        }
      },
      "registrationEnd": "Registration end",
      "registrationStart": "Registration start",
      "restrictionDegree": {
        "values": {
          "1": "Bachelor",
          "16": "without a degree (Employees)",
          "2": "Master",
          "4": "Diploma",
          "8": "Non-degree studies"
        }
      },
      "restrictionUserType": {
        "values": {
          "1": "External users",
          "2": "Students (KIT)",
          "4": "Guests & Partners",
          "8": "Employees"
        }
      },
      "studyGroupSize": "Study group size",
      "visibility": {
        "label": "Visibility",
        "values": {
          "None": "Unreleased",
          "Private": "Private",
          "Public": "Public"
        }
      }
    },
    "proceduretopic": {
      "dayOfWeek": {
        "label": "Day of Week",
        "values": {
          "Friday": {
            "long": "Friday",
            "short": "Fri"
          },
          "Monday": {
            "long": "Monday",
            "short": "Mon"
          },
          "Saturday": {
            "long": "Saturday",
            "short": "Sat"
          },
          "Sunday": {
            "long": "Sunday",
            "short": "Sun"
          },
          "Thursday": {
            "long": "Thursday",
            "short": "Thu"
          },
          "Tuesday": {
            "long": "Tuesday",
            "short": "Tue"
          },
          "Wednesday": {
            "long": "Wednesday",
            "short": "Wed"
          }
        }
      },
      "type": {
        "label": "Type",
        "values": {
          "Event": "Event",
          "SingleAppointment": "Single Appointment",
          "Topic": "Topic",
          "WeeklyAppointment": "Weekly Appointm."
        }
      }
    },
    "registration": {
      "state": {
        "details": {
          "accepted": "You have received a confirmation for the event and are allowed to participate in the event. \nPlease cancel in good time if you are unable to attend the event.",
          "declined": "Unfortunately, we could not offer you a place for this event.",
          "offer": "You have received an offer to participate in this event. \nParticipation must be confirmed within the specified period, otherwise the offer expires.",
          "open": "Your registration has been received, so far no decision has been made about participation. \nIn some allocation procedures, places are allocated at a specific date and time.",
          "waitlist": "You are on the waiting list for this event. \nIf places become available, they will be filled from the waiting list."
        },
        "values": {
          "0": "Open",
          "1": "Waiting list",
          "2": "Offer",
          "3": "Accepted",
          "4": "Declined",
          "accepted": "Accepted",
          "declined": "Declined",
          "offer": "Offer",
          "open": "Open",
          "waitlist": "Waiting list"
        }
      }
    }
  },
  "group": {
    "edit": {
      "access": "Access",
      "confirmationrestriction": "Confirmation restrictions",
      "intro1": "Groups are used to combine several registration procedures in order to mark them as a unit in terms of content or structure. In addition, registration restrictions (maximum X registrations across all registration procedures in this group) can be defined for groups.",
      "intro2": "Groups are valid for all semesters, but the registration restriction is evaluated per semester.",
      "maxboostsperterm": "Max. boosts per term",
      "maxconfirmperterm": "Max. confirmations per term",
      "maxregperterm": "Max. registrations per term",
      "namehint": "This name is displayed in the public view of the registration procedure.",
      "newgroup": "Add group",
      "product": "Course of study",
      "registrationrestriction": "Registration restriction",
      "shortnamehint": "The short name is used in the administration interface and when importing registration procedures. It must be unique.",
      "unitshort": "Unit",
      "visibilityhint": "Controls the visibility of the group in the offer"
    },
    "labels": {
      "allunspecified": "All unspecified"
    }
  },
  "import": {
    "errors": {
      "duplicateprocedure": "There is already a procedure with identical settings, but this entry has no Id yet and would be created twice. Have you possibly used an old list?",
      "invalidfield": {
        "campusEventId": "There is no event for the specified event number or you do not have access to this event",
        "closingdate": "The end of succession must not be before the end of registration",
        "eventtype": "The specified type of event is invalid",
        "group": "The specified group short name does not exist or you do not have access",
        "heading": "No heading was specified or the heading is invalid",
        "id": "The specified id does not exist or you do not have full access to this procedure",
        "name": "A name for the procedure must be specified",
        "primarycontactmail": "A contact email address must be provided",
        "registrationend": "The end of registration must be specified and must be after the start of registration",
        "registrationstart": "The start of registration must be specified"
      },
      "missingfield": {
        "name": "The Name field must be filled in."
      }
    }
  },
  "labels": {
    "addproduct": "Add course of study",
    "allocationType": "Allocation",
    "assignedTopic": "Topic/Appointment",
    "biweekly": "biweekly",
    "createdOn": "Time",
    "currently": "currently",
    "deadline": "Deadline",
    "degrees": "Degrees",
    "firstdrawdate": "Lottery date",
    "group": "Group",
    "groups": "Groups",
    "happiness": "Satisfaction",
    "place": "Position",
    "registration": "Registration",
    "removefavorite": "Remove favorite",
    "resetFilters": "Reset all filters",
    "sortmode": "Sort mode",
    "star": "Star",
    "stars": "Stars",
    "state": "State",
    "title": "Title",
    "type": "Type",
    "unit": "Unit",
    "unlimited": "unlimited",
    "usage": "Usage",
    "visibility": "Visib.",
    "weekly": "weekly"
  },
  "list": {
    "labels": {
      "group": "Event group",
      "heading": "Course catalogue heading",
      "offer": "offer",
      "offering": "Offer",
      "refresh": "Refresh",
      "showDifferentTerm": "Show ongoing registration procedures in other semesters",
      "showEndedProcedures": "Show ended procedures"
    },
    "messages": {
      "noresults": "Unfortunately, no events were found with the selected parameters.",
      "notloggedinwarning": "You are not logged in. You may not see all available events. Please log in with your KIT account or as an external in the upper right corner to register for events."
    }
  },
  "menu": {
    "labels": {
      "campusvvztree": "Campus Course Catalog tree",
      "manage": "Manage",
      "myregistrations": "My registrations",
      "offer": "offer",
      "offering": "Offer"
    }
  },
  "myregistrations": {
    "boostintro": "One or more of your registrations allow boosts 🚀 to prioritize those registrations and increase the chance of getting a spot.",
    "boostsdoc": "More information about Boosts",
    "confirmparticipation": "Confirm participation",
    "confirmparticipationerror": {
      "deadlinepassed": "The deadline for confirming participation has passed.",
      "grouplimitexceeded": "You have reached the maximum number of confirmations within the following group(s). You must first unregister from another event within this group to confirm attendance for this event.",
      "intro": "Confirmation of participation is not possible",
      "notfound": "The registration was not found.",
      "unauthorized": "You are not authorized to confirm participation for this application."
    },
    "confirmparticipationmessage": "Would you like to confirm your participation for  <strong>{0}</strong>?",
    "confirmparticipationmessageext": "<br />This also applies to the members of your study group.",
    "emptylist": "You have not registered to any events in the selected term. \nTo find events to register for, have a look at our ",
    "pendingoffer": "You have an offer to participate in this event. \nYou must confirm your participation by {0} in order not to lose your place.",
    "unregister": {
      "accepted": "Your acceptance for this event will be invalid when you deregister.",
      "button_yes": "Yes, unregister",
      "founder": "You are the founder of this study group. By deregistering, another member of the study group becomes founder.",
      "intro": "Do you really want to unsubscribe from this event?",
      "offer": "You currently have an offer to participate, which will expire if you unsubscribe.",
      "reregisternotpossible": "A new registration is no longer possible, as the registration period has expired.",
      "reregisterpossible": "A new registration is possible as long as the registration period is still running.",
      "title": "Unregister",
      "waitlist": "You are currently on the waiting list. By unregistering, you will lose your place on the waiting list."
    }
  },
  "notifications": {
    "campusvvz": {
      "guidcopied": "The GUID for the heading {0} was copied to the clipboard."
    },
    "exportilias": {
      "loading": "The export is carried out. \nDepending on the number of participants, this can take up to several minutes. \nYou will be informed when the export is complete."
    },
    "group": {
      "copied": "The link to the offer for the group {0} was copied to the clipboard.",
      "create": {
        "success": "The group was created successfully"
      },
      "edit": {
        "error": "A general error has occurred. \nPlease contact support.",
        "notallowed": "You do not have permission to edit this group",
        "notfound": "The group to be edited no longer exists",
        "shortnameconflict": "The short name specified is already being used for another group"
      },
      "update": {
        "success": "The group has been updated"
      }
    },
    "iliasexport": {
      "error": "Unfortunately, an error occurred during the export. \nYou can try the export again or contact support."
    },
    "iliasvalidation": {
      "error": "An error occurred while checking the ILIAS configuration."
    },
    "procedure": {
      "allocationrun": {
        "success": "The allocation process has been started for the selected procedures. \nIt may take a few minutes for all processes to run through."
      },
      "autosave": {
        "error": "An error occurred during automatic saving."
      },
      "copy": {
        "error": "The URL could not be copied to the clipboard.",
        "success": "The URL has been copied to your clipboard."
      },
      "deleted": {
        "success": "The selected, valid registration procedures have been deleted"
      },
      "duplicate": {
        "error": "A general error occurred while copying. \nPlease contact support if you have any questions.",
        "notallowed": "You are not authorized to copy the procedure.",
        "notfound": "The specified method was not found.",
        "success": "The procedure was successfully copied."
      },
      "edit": {
        "error": "A general problem has occurred when loading the registration procedure.",
        "notallowed": "You do not have permission to edit this procedure.",
        "notfound": "The specified procedure does not exist."
      },
      "fakeRegistrationsCreated": "Test applications were generated successfully",
      "group": {
        "assign": {
          "success": "The group was assigned to the selected events"
        },
        "delete": {
          "success": "The group {name} has been deleted"
        },
        "remove": {
          "success": "The group assignments have been removed"
        }
      },
      "join": {
        "success": "You have successfully joined the study group and can find it in the list of your registrations."
      },
      "manager": {
        "add": {
          "alreadyexists": "The person is already in the list of authorized persons",
          "badrequest": "The person could not be added",
          "failed": "A general error occurred while adding the person.",
          "notallowed": "You do not have permission to add people to this procedure",
          "notfound": "The person or procedure was not found",
          "success": "The person has been successfully added"
        },
        "delete": {
          "badrequest": "An error occurred while deleting the access permission",
          "failed": "Failed to remove access permission.",
          "notallowed": "You do not have permission to remove access for this person",
          "notfound": "The specified person could not be found as an authorized person. \nIt may have already been removed.",
          "success": "Permissions have been removed"
        },
        "register": {
          "success": "The selected person has been successfully registered for the procedure."
        }
      },
      "optimize": {
        "failure": "No solution could be found. \nThe amount of available places may not be sufficient.",
        "success": "The optimization was successful."
      },
      "register": {
        "alreadyregistered": "You are already registered for this event. \nYou can find your registration under \"My registrations\".",
        "badrequest": "Your registration could not be saved. \nPlease ensure that all mandatory information is complete.",
        "confirm": {
          "error": "There was an error confirming participation. \nPease contact support if neccessary.",
          "limitreached": "The maximum number of confirmations of participation in the associated group has already been reached. A further confirmation is only possible if a deregistration from another event has taken place.",
          "notallowed": "You are not authorized to carry out a confirmation of participation.",
          "notfound": "The specified registration was not found.",
          "notpossible": "A confirmation of the registration is not possible. \nEither the registration is not in the correct state or the confirmation period has expired.",
          "success": "You have successfully confirmed your participation in the event"
        },
        "edit": {
          "success": "Your changes were saved successfully"
        },
        "error": "An unknown error occurred while saving the login. \nIf necessary, please contact support.",
        "manager": {
          "alreadyregistered": "The selected user is already registered for this procedure"
        },
        "notallowed": "Unfortunately, it is not possible for you to register for this event.",
        "notfound": "The event was not found or has been deleted in the meantime.",
        "notloggedin": "You are not logged in. To perform an event registration you have to log in with your KIT account or register and log in as an external user.",
        "remove": {
          "error": "A general error occurred during deregistration. \nPlease try again later.",
          "notallowed": "You do not have permission to manage this registration.",
          "notfound": "The registration was not found.",
          "success": "You have been successfully deregistered from the event in the system."
        },
        "success": "You have successfully registered for the event and your registration is now in the status \"Open\".",
        "topicfull": "The selected topic/date/event is already full."
      },
      "registeredit": {
        "notallowed": "Editing of the registration is no longer allowed. \nEither the registration period has expired or the registration is in a state that does not allow editing."
      },
      "registrations": {
        "custommessage": {
          "error": "An error occurred while sending the message",
          "success": "Your message has been sent"
        },
        "delete": {
          "error": "An error occurred while deleting the applications",
          "success": "All applications have been deleted."
        },
        "update": {
          "success": "Your registration state changes have been saved successfully."
        }
      },
      "renewaccessid": {
        "success": "The private access link has been renewed"
      },
      "save": {
        "error": "An error during saving has occured.",
        "success": "The changes have been saved"
      },
      "topic": {
        "create": {
          "success": "The topic/appointment {name} was created successfully"
        },
        "createassistant": {
          "success": "The appointments have been created successfully"
        },
        "delete": {
          "confirm": "Would you like to remove the selected {count} topics, dates or events? They will only be permanently deleted when you save them.",
          "event": "The event {name} has been removed.",
          "singleappointment": "The individual appointment {name} has been removed.",
          "success": "The topic {name} was successfully deleted.",
          "topic": "The topic {name} has been removed.",
          "weeklyappointment": "The weekly appointment {name} has been removed."
        },
        "update": {
          "success": "The topic/appointment {name} was successfully updated"
        }
      }
    },
    "registration": {
      "error": "A general error occurred while retrieving the registration. Please contact support if you have any questions.",
      "notallowed": "You are not authorized to access this registration.",
      "notfound": "The requested registration could not be found. It may have been deleted in the meantime."
    },
    "template": {
      "convert": {
        "success": "The template was created successfully"
      },
      "group": {
        "assign": {
          "success": "The group were assigned to the selected templates"
        },
        "delete": {
          "success": "The {name} group was deleted"
        },
        "remove": {
          "success": "The group assignments have been removed"
        }
      }
    },
    "topic": {
      "error": {
        "fetch": "An error occurred when retrieving the topics and appointments."
      },
      "order": {
        "start": "Sort the topics via drag and drop"
      }
    }
  },
  "procedure": {
    "campuscompare": {
      "intro": "This function allows you to compare the registration list in this registration procedure with the registration list of an accessible campus event or examination. Only differences are displayed, no automatic transfer can be performed.",
      "registered": "Registered",
      "showonlydifferent": "Show only deviating entries",
      "title": "Campus registration list comparison",
      "unregistered": "Unregistered"
    },
    "copy": {
      "accessrights": "Access rights",
      "allsettings": "All settings",
      "groupassignment": "Group assignment",
      "intro1": "Would you like to copy the procedure?",
      "intro2": "The following contents are copied:",
      "intro3": "The following contents will NOT be copied:",
      "intro4": "The event is not automatically visible after duplication.",
      "linkedcampusevent": "Linked campus event (only for the same semester)",
      "newtitle": "New title",
      "ratings": "Submitted answers or ratings",
      "requestedinformation": "Requested information",
      "title": "Copy procedure",
      "topicsappointmentsevents": "Topics, appointments, events"
    },
    "delete": {
      "buttonyes": "Yes, delete",
      "continuequestion": "Would you like to continue with the deletion?",
      "followingcontentalsodeleted": "The following content is also deleted",
      "questions": "Questions",
      "title": "Delete {0}?"
    },
    "edit": {
      "access": {
        "access": "Access settings",
        "accesshint": "Specify who can access your procedure.",
        "fullaccessbygroup": "Full access via group",
        "readonlyall": "Read access (all)",
        "readonlyownparticipants": "Read access (own participants)",
        "searchpersontoadd": "Search for person to add",
        "visibility": "Visibility",
        "visibilityhint": "Controls the visibility of your registration procedure",
        "visibilitymissingmandatorywarning": "Not all mandatory information has been filled in, so the visibility of the procedure cannot be set to Private or Public. Please check the tabs with the red dot.",
        "visibilitynonehint1": "The registration procedure is not accessible to anyone other than those authorized to access it and is not listed in the course offer or the course catalog",
        "visibilitynonehint2": "This is the default value after creating a new procedure so that you can make all the necessary settings at your leisure.",
        "visibilityprivatehint": "Private procedures are not listed in the course catalog or in the offer. Access is possible via a link specific to the registration procedure (see overview). This link can be forwarded to the desired participants, e.g. by e-mail.",
        "visibilitypublichint": "The procedure is publicly visible in the offer and in the course catalog.",
        "warningoptimizationwithouttopics": "You have specified optimal distribution as an allocation procedure, but have not yet created any topics, dates or events. To avoid accidental activation and any resulting problems, the visibility for procedures with optimal distribution can only be changed once topics, dates or events have also been created."
      },
      "autoCcMail": "Auto mail cc",
      "autoCcMailtooltip": "If set, the specified address is used as CC for all mails that are sent manually via the participant list.",
      "campuslistown": "Own events",
      "campuslistrole": "Events in your role",
      "campuslistunit": "Events in your organisational unit",
      "caption": "Caption",
      "captiontooltip": "The caption appears below the course title. You can enter important additional information here, e.g. lecturers or dates.",
      "createnew": "Create new procedure",
      "createprocedure": "Create procedure",
      "createsuccesstext": "Your procedure has been successfully created. Now go to the settings to enter additional information. Tabs with a red dot contain mandatory information that still needs to be completed.",
      "createtemplate": "Create template",
      "description": "Description text",
      "descriptionhint": "Further information about your event. Displayed in the detailed view and during registration.",
      "eventreminder": "Event reminder",
      "eventreminderhint": "You can set a time for an automatic reminder for the event. At this time, all persons with a confirmation will receive an e-mail with the dates of the linked campus event. You can add your own content .",
      "gotosettings": "Go to settings",
      "lecturermode": {
        "full": "Full access",
        "none": "No access",
        "readonly": "Read access"
      },
      "lecturermodehint": "What access should lecturers and editors of this campus event have to this procedure? (only applies to new procedures)",
      "lecturermodelabel": "Access level for campus event lecturers and editors",
      "linkedcampusevent": "Linked campus event (optional)",
      "linkedcampuseventtooltip": "A linked campus event enables an ILIAS participant export, the display of information from the event in the offer and an automatic reference to the registration procedure in the public course catalog.",
      "missingmandatorytext": "Mandatory information is missing, but a private or public visibility has been defined - saving not possible.",
      "nametooltip": "This is the main name of your registration procedure. The name appears in the offer, in various e-mails and under My registrations.",
      "nocampuseventsfound": "No events with access rights found",
      "notificationcontent": "Notification content",
      "notificationcontentaccepted": "Notification: Status change to confirmation",
      "notificationcontentautodeclined": "Notification: Status change to rejection by automatic system",
      "notificationcontenteventreminder": "Additional content for Notification: Event reminder",
      "notificationcontenthint": "You can add your own content to the automatically sent status e-mails. Simply enter the text (e.g. access data for Zoom or information on the first appointment) in the corresponding fields.",
      "notificationcontentnewreg": "Notification: Confirmation of receipt of registration",
      "notificationcontentnocustomtext": "<without own content>",
      "notificationcontentoffer": "Notification: Status change to participation offer",
      "notificationcontentwaitlist": "Notification: Status change to waiting list",
      "notifications": "Notifications",
      "notificationshint": "You can activate automatic notifications for participant changes.",
      "notificationsnewreg": "Notification of new registrations and learning group membership",
      "notificationsnewregtooltip": "All persons registered in the procedure receive an e-mail when a new registration is received.",
      "notificationsunreg": "Notification of deregistration or automatic cancellation for accepted registrations or offers",
      "notificationsunregtooltip": "If a participant in Accepted or Offer status unsubscribes or the confirmation of participation is not received on time, an e-mail notification to all registered persons can be activated here.",
      "notificationsunregwaitlist": "Notification of deregistration from waiting list",
      "notificationsunregwaitlisttooltip": "Sends a notification to all administrators when a person on the waiting list unsubscribes.",
      "offeringplacement": "Offer list placement",
      "offeringplacementdescription": "This information determines where your registration procedure is displayed in the offer list.",
      "primaryContactMail": "Contact e-mail",
      "primarycontactmailtooltip": "The e-mail address is used as the sender for automatic e-mails and is displayed as the contact address.",
      "ratingtester": {
        "ratingnotvalid": "Rating is invalid",
        "ratingvalid": "Rating is valid",
        "title": "Test your rating configuration"
      },
      "titleimage": "Cover picture",
      "titleimagechoose": "Select image file",
      "titleimagechoosehint": "It must be an image file with a maximum size of 200kb",
      "titleimagehint": "You can define a cover image that will be displayed in your offer details and during registration.",
      "titleimageonleft": "On the left",
      "titleimageontop": "On top",
      "titleimageposition1": "Here you can control where your cover image is displayed in the login screen and in the preview. On the left, the image is positioned small next to the text (good for images in poster format), at the top it appears in full width above the text (good for horizontal images).",
      "titleimageposition2": "The images are adapted to the available space depending on the device.",
      "titleimageremove": "Remove image",
      "titleimageupload": "Upload cover picture"
    },
    "export": {
      "additionalsettings": "Additional information",
      "excelfile": "Excel file",
      "exportstructure": "Structure of the Excel export",
      "exportstructureallinone": "All persons in one worksheet",
      "exportstructurefilepertopic": "One file (ZIP archive) per topic/appointment/event",
      "exportstructurehoc": "HoC participant list",
      "exportstructuresheetpertopic": "One worksheet per topic/appointment/event",
      "iliasexportbutton": "Open ILIAS Export",
      "iliasexportintro1": "With the ILIAS export you can export your participant lists to your ILIAS courses. There are up to three different options available, depending on the configuration of your registration procedure.",
      "iliasexportintro2": "Click on the button to open the ILIAS export that checks the current settings and shows you the possible export variants.",
      "includekitaccount": "KIT Account",
      "includekitcardnumber": "Number of the KIT card (only for accepted students)",
      "includeplanned": "Use planned statuses",
      "includeuploadedfiles": "Uploaded files (additional information)",
      "includeuploadedfilestooltip": "Always creates a ZIP file that also contains all the files uploaded in the registrations.",
      "intro": "You can use this function to download a list of participants. We offer you the option of customizing the structure of the list so that you can continue working with it in the best possible way.",
      "jsonfile": "JSON file",
      "onlypublished": "Only use published statuses",
      "plannedchanges": "Planned registration states",
      "statustoexport": "Include the following registration states",
      "title": "Excel Export"
    },
    "iliasexport": {
      "campusevent": "Campus event",
      "exportoptions": "Export options",
      "exportpossibleas": "Export possible as",
      "followingparticipantscannotbetransferred": "The following persons cannot be transferred:",
      "followingparticipantscannotbetransferredhint": "The named persons cannot be exported to ILIAS as they are not known in ILIAS. Staff and students must have logged in to ILIAS at least once. External persons cannot currently be transferred automatically.",
      "groupincourse": "Group in the course of the procedure",
      "missingcampusevent": "Missing Campus event",
      "missingiliascourse": "missing ILIAS course",
      "nocourseaccess": "No access to course",
      "participantswithacceptancefor": "Participants with accepted state for",
      "procedureallparticipants": "Registration procedure (all participants)",
      "selectedforexport": "Selected for export",
      "selectexportstyle": "Please select how you would like to export the participants. In the \"Selected for export\" column at the top, you can see which components are exported and how.",
      "stylenotavailableintro": "This mode is currently not available to you, since",
      "stylenotavailablenocampusevent": "no campus event is linked to the registration procedure. This means that no ILIAS course can be determined.",
      "stylenotavailablenocourseaccess": "you do not have access to the ILIAS course of the campus event.",
      "stylenotavailablenoeventtopics": "there are no events with an ILIAS course entered as topics in the procedure or you do not have access to these courses.",
      "stylenotavailablenoiliascourse": "the campus event does not have an ILIAS course.",
      "styleprimaryevent": "Main event of the procedure",
      "styleprimaryeventandsubgroups": "Main event and event-topics of the procedure",
      "styleprimaryeventandsubgroupshint": "With this export, the respective assigned participants for the procedure's event-topics are included in the ILIAS course of the event. This allows you to distribute the participants to the various courses directly in ILIAS. In addition, all participants are entered in the course of the event linked to the registration procedure, so that you have a common course for all participants in addition to the individual courses.",
      "styleprimaryeventhint": "In this mode, all authorized persons are exported as members in the ILIAS course of the event linked to the registration procedure.",
      "stylesubgroups": "Event topics of the procedure",
      "stylesubgroupshint": "With this export, the respective assigned participants for procedure's event topics are included in the ILIAS course of the event. This allows you to assign the participants directly to the various ILIAS courses.",
      "stylewithgroups": "with groups",
      "stylewithgroupshint": "In this mode, all accepted persons are exported as members in the ILIAS course of the event linked to the registration procedure. In addition, a group is created in the course for each topic/appointment/event and persons with this assigned topic are added to the group so that separate workspaces exist within the course. If a person is entered in the topic, they are automatically entered as the group administrator."
    },
    "import": {
      "buttonimport": "Import",
      "choosefile": "Select file for import",
      "erroroccured": "A problem was detected when processing the file",
      "errorsoccured": "Errors have occurred",
      "filehint": "Please select an Excel file",
      "importfile": "Import file",
      "intro": "This function allows you to import the registration procedures into the system via an Excel file. In this way, you can update or create many new procedures in one go. You can use the export under Mass editing as a template.",
      "line": "Line",
      "resultcreated": "Newly created",
      "resultnochanges": "No changes",
      "resultupdated": "Updated",
      "term": "TERM",
      "title": "Import registration procedures"
    },
    "labels": {
      "access": "Access and visibility",
      "additionalsettings": "Additional settings",
      "basicsettings": "Basic settings",
      "currentlynooutstandingconfirmations": "You currently have no outstanding confirmations of participation",
      "groupfilteractive": "Group filter active",
      "registrationandassignment": "Registration and Assignment",
      "restrictionDegree": "Degrees",
      "restrictionProducts": "Courses",
      "restrictionUserType": "User groups",
      "restrictions": "Restrictions",
      "showallprocedures": "Show all procedures",
      "showonlyingroups": "Only show procedures in the selected group",
      "statsforselected": "Statistics on selected procedures",
      "topicsandappointments": "Topics and Appointments"
    },
    "manage": {
      "additionalinfopopupplaceholder": "Question/Label (ESC to cancel)",
      "additionalinformation": "Request for additional information",
      "additionalinformationclickonlabel": "Click to enter description/question text",
      "addquestion": "Add question",
      "addtogroups": "Add to group",
      "allocationtypemandatorytext": "If it is a compulsory course with fewer places than registrations and no further course can be offered to reduce the surplus, places must be allocated according to the SPO in accordance with the progress of studies and in compliance with the disadvantage compensation statutes. It should be noted that students on the degree program have priority over students from outside the degree program. Among students within the degree program, decisions may be made on the basis of academic progress (not just subject-related semesters); in the case of equal academic progress, further selection criteria must be defined; these can be: <ul><li>In the case of equal academic progress, according to waiting time</li><li>In the case of equal waiting time, by lot</li><li>In the case of divisible courses, according to the first letter of the surname</li></ul>",
      "allocationtypemandatorytitle": "Note on compulsory courses with an insufficient number of places",
      "allocationtypeusefulfor": "Particularly suitable for",
      "answers": "Answers",
      "availableplaces": "Available seats",
      "availableplaceshint": "Number of places in the entire registration process. 0 for unlimited.",
      "availableplacestopichint": "Number of places for this theme. 0 or empty for unlimited.",
      "confirmationdeadlinedays": "Confirmation period in days",
      "confirmationdeadlinedayshint": "Number of days after acceptance in which the confirmation must be made",
      "confirmationdeadlinespecific": "Absolute confirmation period",
      "confirmationdeadlinespecifichint": "If you want confirmations by a fixed date, you can select this date here.",
      "confirmationrequired": "Confirmation required",
      "copygrouplink": "Copy link to the offer of the group in the selected semester",
      "createprocedurefromtemplate": "New procedure from template",
      "errors": {
        "closingdatemustbeafterend": "The succession date must be after the application deadline",
        "regendmustbeafterbegin": "The registration end date must be after the registration start date"
      },
      "events": "Events",
      "export": "Export",
      "exportexcel": "Export (Excel)",
      "exportwithcampuseventexcel": "Export incl. all campus events (Excel)",
      "fileuploadnote": "Notes on file upload",
      "fileuploadnotedeletion": "Uploaded files are automatically deleted after 1 year.",
      "fileuploadnotemaxsize": "The maximum file size is 5MB.",
      "fileuploadnotepdfonly": "Only PDF files can be uploaded.",
      "hideendedprocedures": "Hide ended procedures",
      "importprocedures": "Import procedures",
      "linkedcampusevent": "Linked campus event",
      "linkinoffer": "Link to the offer",
      "linkinoffering": "Link to offer list",
      "lotterydate": "Lottery/Ranking date",
      "massedit": "Mass editing",
      "mygroups": "My groups",
      "myprocedures": "My registration procedures",
      "mytemplates": "My templates",
      "newprocedure": "New procedure",
      "nogroups": "You don't have any groups yet.",
      "noprocedures": "There are no registration procedures for you this semester.",
      "notemplates": "You have not yet created any templates.",
      "openincampus": "Open in Campus",
      "openinstudyportal": "Open in the student portal",
      "overview": "Overview",
      "participantlistflat": "Overall list of participants",
      "participantlistzip": "Participant lists (ZIP)",
      "participantsearch": "Participant search",
      "participantsearchhint": "You can use this tool to view a list of existing registrations for a person in the selected semester. Only registration procedures to which you have access rights are displayed.",
      "placesacc": "places",
      "placesandstudygroups": "Places and study groups",
      "privatelink": "Access link for private procedures",
      "privatelinkcaption": "This address is only active if the visibility is set to at least private.",
      "publiclink": "Public address",
      "publiclinkcaption": "This address is only active when the visibility is set to Public.",
      "regenerate": "Regenerate",
      "registrationdeadline": "Registration deadline",
      "removefromgroup": "Remove from group",
      "removefromgroups": "Remove from groups",
      "runallocationmanually": "Execute allocation process manually",
      "runallocationmanuallyintro1": "Would you like to carry out the respective allocation process manually for the following application procedures?",
      "runallocationmanuallyintro2": "Only application procedures with the allocation types \"First Come First Served\", \"Lottery\" and \"Semester Ranking\" are supported. This action has no influence on regular allocation procedures and is only carried out if the closing date has not yet been reached.",
      "runallocationmanuallyonlywaitlist": "Only consider applications on the waiting list",
      "searcherror": "An error occurred during the search.",
      "selectedprocedures": "Selected procedures",
      "selectedtemplates": "Selected templates",
      "selectedtopics": "Selected Topics/Appointments",
      "settings": {
        "registration": {
          "disablewaitlisttoggle": "Disable waiting list"
        },
        "restrictions": {
          "alloweddegrees": "Allowed Degrees",
          "allowedproducts": "Allowed degree programs",
          "allowedproductsall": "All degree programs are allowed.",
          "allowedproductsconsecutive": "Allow prospective graduates to register for consecutive degree programs",
          "allowedproductsconsecutivetooltip": "If this option is activated, it is possible for students to register if they are about to graduate (e.g. 180 LP registered in the B.Sc. Architecture) and the consecutive degree program is allowed (e.g. M.Sc. Architecture), even if the current degree program itself is not allowed. This is intended to make it easier for Bachelor's students who are about to graduate to register for Master's courses.",
          "allowedproductsid": "Id",
          "allowedproductsselect": "Select course program",
          "allowedusertypes": "Allowed user groups",
          "brickrestrictions": "Restrictions regarding courses",
          "brickrestrictionsallpassed": "All conditions must be met",
          "brickrestrictionsnone": "No requirements for courses are defined.",
          "brickrestrictionsonepassed": "One of the conditions must be met",
          "brickrestrictionstext": "Here you can define conditions for courses that must be fulfilled in order to register for this course. These conditions automatically restrict the groups of people to KIT students.",
          "brickrestrictionstypes": {
            "notpassed": "must not be passed",
            "notstarted": "must not be started",
            "passed": "must be passed",
            "started": "must be started"
          },
          "bricksearch": "Search course",
          "bricksearchnoresult": "No search result or search term too short (at least 4 characters)",
          "groupswithlimits": "This procedure is linked to groups that additionally define the following registration restrictions",
          "intro": "On this tab, you can define restrictions for registration. If you define restrictions, only people who meet all the requirements can register for this event.",
          "looserestrictions": "Ease restrictions",
          "looserestrictionstext": "If you activate this option, registration is possible even if the restrictions are not met. However, the person will receive a warning.",
          "looserestrictionstoggle": "Enable registration despite restrictions (with warning message)",
          "noproceduresfound": "No procedures with access rights found",
          "procedures": {
            "label": "Restrictions regarding other registration procedures",
            "none": "No restrictions to other registration procedures are defined.",
            "text": "You can link the registration to this procedure to the registration status of another procedure.",
            "types": {
              "accepted": "must be accepted",
              "notaccepted": "must not be accepted",
              "notregistered": "must not be registered",
              "registered": "must be registered"
            }
          },
          "procedureselect": "Select procedure"
        },
        "topics": {
          "appointment": "Appointment",
          "assistcount": "Count",
          "assistduration": "Duration",
          "assistfirstdate": "First appointment",
          "assistinminutes": "in minutes",
          "assistintervall": "Interval",
          "assistintro": "You can use this wizard to create several consecutive appointments. The interval is the time between the start of two appointments.",
          "assistintrohint": "Tip: To create a daily appointment, use the value 1440 (=24h) as the interval, for weekly appointments use the value 10080 (=1w).",
          "assistlist": "The following appointments are generated based on your entries",
          "assistplaceshint": "Number of places for each of these appointments. 0 or empty for unlimited.",
          "averagerating": "Ø-Rating",
          "averageratingrated": "Ø rating (rated only)",
          "completerating": "Complete Rating",
          "completeratingcaption": "Do all topics or appointments have to be rated? If not, only the specified ratings must be fulfilled.",
          "configtest": "Test configuration",
          "currentallocationprocedure": "Your current allocation procedure",
          "descriptionhint": "Here you can enter an additional description text that will be displayed in the details and during registration.",
          "enablewaitlist": "Activate waiting list",
          "enablewaitlistcaption": "Should it be possible to register for appointments or topics that are already full? In this case, the registration will be placed on the waiting list for this appointment/topic and will move up when places become available. If the waiting list is deactivated, it is not possible to register for full topics or dates. (Default: No)",
          "errors": {
            "invalidaverage": "An average required rating must be between 1 and 5.",
            "invalidsamepercentage": "The maximum proportion of equal ratings must be between 20% and 100%.",
            "lowerlargerupperbound1": "The lower limit for 1 star must not be greater than the upper limit.",
            "lowerlargerupperbound2": "The lower limit for 2 stars must not be greater than the upper limit.",
            "lowerlargerupperbound3": "The lower limit for 3 stars must not be greater than the upper limit.",
            "lowerlargerupperbound4": "The lower limit for 4 stars must not be greater than the upper limit.",
            "lowerlargerupperbound5": "The lower limit for 5 stars must not be greater than the upper limit.",
            "sumlargertopiccount": "The sum of the minimum assessments to be submitted must not be greater than the number of topics/appointments.",
            "upperboundstoolowforcompleterating": "The upper limits do not allow a complete rating."
          },
          "fcfstopicselection": "With First Come First Served with a choice of topic/appointment, registration is made for a selected topic or appointment. Registration is only possible if places are available in the topic or appointment or if the waiting list is activated.",
          "heatmapdialogintro": "In this table, you will see a heat map for all your topics or appointments, the colors of which represent the frequency of the respective rating for the topic. The darker the color, the more ratings have been concentrated on this number of stars. The heat map provides a more detailed insight into the popularity of topics or dates.",
          "heatmapdialogtitle": "Heatmap of the number of respective ratings",
          "intro": "Topics, appointments and events can be used in the \"Optimized Distribution\" and \"First Come First Served\" registration procedures to activate a preselection or rating during registration. This rating or preselection can be taken into account automatically or manually when allocating places.<br /><br />Further information can be found in the",
          "label": "Topics / Appointments",
          "maxsamepercentage": "Max. percentage of equal ratings",
          "maxsamepercentagecaption": "in percent, e.g. 50%, blank for unused",
          "noglobalplaces": "You have specified a maximum number of places for the procedure. In \"Select an entry\" mode, only the topic-specific number of places is taken into account; the global number of places is not used.",
          "norating": "does not take ratings into account when allocating places, so this option is deactivated.",
          "noratingorselection": "does not take ratings or the selection of topics into account when allocating places, so these options are deactivated.",
          "pastappointments": "Allow past appointments",
          "pastappointmentscaption": "Should it be possible to select appointments that lie in the past? (Default: No)",
          "plausabilitycheck": "Plausibility check",
          "plausabilitycheckfailed": "The specified configuration cannot be fulfilled by applicants",
          "ratingcount": "Ratings",
          "requiredaveragerating": "Required average rating",
          "requiredaverageratingcaption": "in stars (between 1 and 5, e.g. 2.5, blank for not used)",
          "requiredratings": "Number of ratings to be submitted",
          "requiredratingscaption": "You can enter both the minimum and maximum ratings to be given. If you want to force explicit ratings, set both values to the same number.",
          "sortmodetooltip": "Switches to a mode in which the entries can be reordered using drag & drop.",
          "topicheatmap": "Ratings heatmap",
          "typenone": "No rating or selection",
          "typenonehint": "The topics or appointments created do not play a role when registering. However, they can be assigned when allocating places.",
          "typerating": "Rating with stars",
          "typeratinghint": "During registration, the topics or appointments can or must be rated with 1-5 stars.",
          "typeselection": "Selecting an entry",
          "typeselectionhint": "Registration requires the selection of a topic or appointment.",
          "unrated": "No rating",
          "usage": "Usage",
          "usageoptdist": "In the \"Optimal distribution\" allocation procedure, only the star rating is available, as the rating is the basis for the distribution.",
          "usagetext": "You have the choice of whether interested parties have to rate the topics, appointments or events created directly when registering or whether they have to choose between them."
        }
      },
      "showalladmin": "Show all procedures (Admin)",
      "statistics": "Statistics",
      "statsintro": "This semester there are a total of",
      "studygrouphint": "0 = no learning groups allowed",
      "studygroupmaxsize": "Maximum Size",
      "studygroupsize": "Study group size",
      "successiondate": "Succession period",
      "termunpublished": "The selected semester is not published. Registration procedures in this semester are not yet visible in the offer.",
      "texts": {
        "additionalinformation": "If you would like to request further information when registering, you can specify this here.",
        "allocationmandatorynote": "Please note: for <strong>compulsory courses</strong> with fewer places in total than registrations, places are allocated according to <strong>study progress</strong>. Select the \"Manual selection\" option.",
        "allocationtype": "Information on the distribution of seats",
        "allocationtypepopup": "You can choose between a total of 5 different space allocation procedures. Click on the relevant procedure below to obtain more information on the respective procedure and to decide on the most suitable procedure for your application.",
        "configuretopicsnote": "You can configure the topics or dates for which you would like to carry out a distribution in the \"Topics and Appointments\" tab.",
        "confirmation": "Should a confirmation of participation be required? If confirmation is not received in time, the participant will automatically receive a rejection and the place will become free.",
        "disablewaitlist": "You can use this option to deactivate the waiting list in the allocation procedure. ",
        "disablewaitlistwarn": "Please note: When activating this option, all existing registrations with the status \"Waiting list\" will automatically receive a rejection.",
        "registrationperiodnote": "You can now also leave the start and end of registration blank. It then becomes a permanently open event."
      },
      "timeline": "Timeline",
      "topicsappointments": "Topics/Appointments",
      "totemplate": {
        "intro1": "Would you like to create a new template from the selected procedure?",
        "linkedcampusevent": "Linked campus event",
        "templatename": "Name of the template",
        "title": "Create template from procedure",
        "topicevents": "Event topics"
      },
      "triggermanualallocation": "Execute allocation procedure manually",
      "unpublishedprocedures": "You have unpublished procedures. These are not yet visible in the offer. You can control the visibility in the \"Access\" tab of the registration procedure's settings.",
      "usage": "Usage"
    },
    "newsettingsadditionalinfo": "Further information on the settings",
    "newsettingsintro": "We have revised the settings. You can now find topics/appointments and access in the Settings tab.",
    "registrations": {
      "addnewdialog": {
        "findperson": "Find person",
        "intro": "You can use this function to register on behalf of another person who cannot register themselves due to restrictions or the registration period. However, it is not possible to submit ratings or additional information in this way.",
        "personnotifiedtext": "The person is automatically informed of their registration.",
        "title": "Add new application"
      },
      "addregistration": "Add registration",
      "appointmentconflict": "Scheduling conflict with another event",
      "boostedregistration": "Boosted registration",
      "bydefaultweighting": "according to standard weighting",
      "campuscompare": "Campus registrations compare",
      "choosetopic": "Select topic",
      "declineopen": "Decline open applications",
      "filterbyperson": "Filter by person",
      "labels": {
        "createdon": "Registration date",
        "overbooking": "Overbooking",
        "points": "Points",
        "registrationlist": "Registration list",
        "regstatus": "Registration status",
        "topicapp": "Topic/Appointm.",
        "topicplaces": "Topic places",
        "waitlistplace": "Waiting list place"
      },
      "optimizationallowedassignments": "Allowed Assignments",
      "optimizationarchive": "Archive",
      "optimizationarchived": "Archived optimizations",
      "optimizationassignedplaces": "Assigned Places",
      "optimizationassignedratings": "Assigned Ratings",
      "optimizationcontinueintro": "You continue a distribution that has already begun. This function is used to optimally distribute registrations that are still open to free or vacant places. Only free places and open registrations are used. You can also increase the number of places available for the individual dates to create more space.",
      "optimizationdeletearchived": "Delete archived entry",
      "optimizationguaranteedplaces": "Guaranteed Participation",
      "optimizationguaranteedplacestooltip": "Here you can control whether each registered person should be allocated a place. In this case, there must be enough places available. If you deactivate this option, some participants will receive a rejection if there is not enough space.",
      "optimizationminpersonspertopic": "Min. participants per topic/appointment",
      "optimizationnoplace": "No place received (=rejection)",
      "optimizationnoplaceorconflict": "No place assigned or scheduling conflict",
      "optimizationonestarassignment": "1 star assignments",
      "optimizationoptimize": "Optimize",
      "optimizationparameters": "Optimization parameters",
      "optimizationplaces": "Places",
      "optimizationrelative0": "Highest rating received",
      "optimizationrelative1": "Slight deviation from the highest rating (1 star less)",
      "optimizationrelative2": "Average deviation from highest rating (2 stars less)",
      "optimizationrelative3": "Large deviation from highest rating (3 stars less)",
      "optimizationrelative4": "Very large deviation from the highest rating (4 stars less)",
      "optimizationrelativehappiness": "Relative satisfaction",
      "optimizationresetplaces": "Reset number of places",
      "optimizationrestoreconfig": "Restore configuration and assignments",
      "optimizationresult": "Optimization result",
      "optimizationsetequalplaces": "Set number of places to equal distribution",
      "optimizationsetequalplacesdialogmessage": "Would you like to overwrite the number of places for all topics or appointments with the appropriate number of places for an even distribution? In this case, the number of places is set to round up (number of participants / number of topics/appointments).",
      "optimizationsetequalplacesdialogtitle": "Set the same number of places for all topics/appointments",
      "optimizationtopicisunrated": "The assigned topic/appointment was not rated.",
      "optimizationtopics": "Topics",
      "optimizationunratedtopics": "unrated Topics",
      "optimizationwarninginsufficientplaces": "There are not enough places for all participants. Please increase the number of places for the topics or appointments or deactivate the guaranteed participation. In this case, not all registrations will be accepted.",
      "optimizationweighting": "Weighting",
      "optimizationweightingtooltip": "You use this setting to control how the ratings affect the optimization algorithm. In the linear scale, all assignments are rated equally (i.e. an improvement from 1 to 2 stars is worth the same as an improvement from 4 to 5 stars). In the standard scale, improvements in low ratings are rated more highly (i.e. the system rates a 2-star assignment as an alternative to a 1-star assignment more positively than an improvement from 4 to 5 stars)",
      "plannedchanges1": "You have planned changes for ",
      "plannedchanges2": "that were automatically saved. The affected entries are marked in red and with a star. The previous value is framed by a blue dotted border.",
      "plannedchanges3": "Changes can be discarded or published and are preserved when you change pages or log out.",
      "registrationmerge": {
        "intro": "Would you like to merge the selected {0} registrations into one registration? They will then form a study group. Please select the registration that will become the study group leader. All other registrations will be added to this one. Please note that information such as further details and assessments of the original registrations will be lost.",
        "title": "Merge registrations"
      },
      "selectedtopicorappointment": "Topic or appointment selected during registration",
      "showstudygroupnames": "Show all study group member names",
      "splitfromregistration": {
        "text": "Would you like to remove {0} from this study group? A new individual registration is created for the person, which takes over the ratings but not the information from the study group.",
        "title": "Split person from study group"
      },
      "stickyview": "Sticky table head",
      "texts": {
        "declineopentextcount": "You have <strong>{0} applications</strong> in Open status.<br /><br />Would you like to set them to Declined? The changes will only take effect after you subsequently publish them via Apply.",
        "discardchangestext": "You have made changes that have not been saved. Do you want to discard them?",
        "discardchangestextcount": "You have made <strong>{0} changes</strong>.<br />Are you sure you want to discard all changes? All changed entries will be reset to the currently published state.",
        "discardchangestitle": "Discard changes?",
        "publishchangestitle": "Publish changes",
        "savechangestextcount": "You have <strong>{0} pending changes</strong>.<br />Would you like to publish them now?<br /><br /><em>The affected persons will be informed of their new status assignment by e-mail.</em>",
        "savechangestitle": "Save changes"
      },
      "viewbuttons": "Button display",
      "viewextcharts": "Advanced occupancy display",
      "viewextended": "Extended information"
    },
    "sendmessage": {
      "allregistrationsinstate": "All registrations in state ...",
      "hint1": "Your message will be sent to ",
      "hint2": ". You will automatically receive a copy.",
      "individualregistrations": "Individual registrations"
    },
    "showoncampusplan": "Show on the campus map"
  },
  "procedureview": {
    "availableplaces": "Available Places",
    "conflict": "Scheduling conflict",
    "contact": "Contact address for this event",
    "contactdesc": "If you have any questions about this event or your registration, please send an email to the above address.",
    "disablewaitlist": "Disable waitlist",
    "groupintro": "This event is part of the group",
    "grouplimitboosttext": "Boosts can be assigned in this group to increase the weighting of your application",
    "grouplimitconfirmationtext": "Within this group the number of possible confirmations of participation per semester is limited",
    "grouplimitother": "All degree programs not explicitly listed",
    "grouplimits": {
      "boostsingular": "Boost",
      "boostsplural": "Boosts",
      "confirmationplural": "Confirmations of participation",
      "confirmationsingular": "Confirmation of participation",
      "noboosts": "No boosts allowed",
      "noconfirmation": "No confirmation of participation possible",
      "nolimit": "no limit",
      "noregistration": "no registration possible",
      "registrationplural": "registrations",
      "registrationpluralsolo": "Registrations",
      "registrationsingular": "registration"
    },
    "grouplimittext": "Within this group, the number of possible registrations per semester is limited",
    "groupshortname": "short name",
    "headings": {
      "additionalInformation": "Further information",
      "group": "Group",
      "registrationandallocation": "Registration and Place Allocation",
      "requiredinformation": "Information during registration",
      "restrictions": "Restrictions",
      "studygroup": "Study group",
      "topicsappointmentsevents": "Topics, Appointments and Events",
      "topicselection": "Selected topic/date/event"
    },
    "linkedcampusevent": "Associated campus event",
    "note": "Content",
    "notloggedinwarning": "You are not logged in. Please log in at the top right to register for this event.",
    "registrationlimited": "Registration for this event is restricted to:",
    "registrationperiod": "Registration Period",
    "requiredinformation": "During registration you will need to provide the following information",
    "studygroupfounder": "Founder",
    "studygroupintro": "This event allows study groups with up to {0} members. \nAll members receive the same status and are assigned the same topic, appointment or event.",
    "studygrouplink": "Link to join your study group",
    "studygrouplinkintro": "You can invite more people to your study group by sharing the link below",
    "studygroupmemberlist": "Currently this registration has the following members",
    "studygroupsizedesc": "up to {0} members",
    "taepresent": "The following topics, appointments or events were entered for these procedure, which you can be assigned to.",
    "taeratingrequired": "During registration, you must submit a rating for them.",
    "yourrating": "Your rating",
    "yourregstatus": "Your registration status",
    "yourresponse": "Your Answer",
    "yourresponses": "Your answers",
    "yourtopic": "Your assigned topic/appointment/event",
    "yourtopicassignment": "You have been assigned this topic/appointment/event!",
    "yourtopicselection": "You have selected this topic/date/event.",
    "yourupload": "Your uploaded document"
  },
  "registration": {
    "add": {
      "backtooffer": "Back to offer",
      "backtooffering": "Back to the offer list",
      "degreesallowed": "Allowed degrees",
      "degreesyou": "Your selected degree",
      "enableautoupdate": "Activate places update",
      "error": {
        "heading": "Registration not possible",
        "intro": "Unfortunately, a registration is not possible for you:",
        "productnoreg": "Registration is not available for your course of study.",
        "regconditional": "Registration is subject to certain conditions",
        "regconditionaltext": "Registration for this event is only possible for you under the conditions mentioned below. The organizer has allowed that registration is possible even if the prerequisites are not met, but participation can be excluded afterwards. Please make sure that you meet the requirements at the time of the event."
      },
      "event": "Event",
      "intro": "You have successfully signed up.",
      "newregistration": "New registration",
      "noautoupdate": "You have been inactive for too long. To reactivate the automatic update of available seats, click here",
      "nofreeplaces": "No free places",
      "notice": "Please note that a sign up alone does not constitute a right to participate.",
      "notificationnotice": "You will receive an automatic registration confirmation from the system with the current status as well as further e-mails in case of status changes. You can find the current status at any time under \"My registrations\".",
      "productsallowed": "Allowed courses of study",
      "productsyou": "Your selected course of study",
      "registernow": "Register now",
      "regsperterm": "registrations per semester",
      "title": "Registration successful",
      "usergroupallowed": "Allowed user groups",
      "usergroupyou": "Your user group"
    },
    "buttons": {
      "edit": "Edit",
      "registrationadd": "New Registration",
      "registrationedit": "Edit registration",
      "registrationgoto": "Go to registration",
      "registrationview": "View registration",
      "view": "View"
    },
    "errors": {
      "alreadyended": "The registration period for the event has expired.",
      "alreadyregistered": "You are already registered for this event.",
      "bricknotpassed": "In order to be able to register for this course, the following partial achievement must not have been passed:",
      "bricknotstarted": "In order to register for this course, the following course must not have been started yet:",
      "brickpassed": "Registration is only possible if the following course has already been passed:",
      "brickstarted": "Registration is only possible if the following course has already been started:",
      "cannotjoinfullprocedure": "Joining a registration with an acceptance status is only possible if there are still free places in the event. \nYou can register yourself and wait on the waiting list for an available place.",
      "cannotjoinfulltopic": "Joining an enrollment in an accepted status is only possible if there are still available seats in the selected topic, date or event. You can register independently and wait in the waiting list for an available place.",
      "grouplimitreached": "You have reached the maximum number of registrations in this event group.",
      "invaliddegree": "Registration is only permitted for some degrees. \nYou currently do not have a degree in one of the approved degrees.",
      "invalidproduct": "Registration is only permitted for specific courses. \nYou are currently not enrolled in any of the permitted courses.",
      "invalidstate": "The registration is in a status that does not allow joining the study group.",
      "invalidusertype": "You are not part of the permitted user groups for this event.",
      "notstarted": "The registration period has not yet started.",
      "placesfull": "The available places for this event are already taken and there is no waiting list.",
      "restrictionaccepted": "Registration requires that you have already received a confirmation for the following event:",
      "restrictionnotaccepted": "In order to register for this event, you must not have already been accepted for the following event:",
      "restrictionnotregistered": "In order to register for this event, you must not already be registered for the following event:",
      "restrictionregistered": "Registration requires that you are already registered for the following event:",
      "studygroupfull": "The learning group has reached the maximum number of participants."
    },
    "headings": {
      "masterdata": "Master data",
      "ratings": "Ratings",
      "requestedinformation": "Questions",
      "topicselection": "Selection of topics, appointments or events"
    },
    "join": {
      "error": {
        "intro": "Unfortunately, joining the specified study group is not possible for you",
        "title": "Study group join not possible"
      },
      "intro": "You are about to join a study group for the above event.<br /> Members of a learning group always receive the same status (waitlist/acceptance/rejection) and, if applicable, the same topic or date assignment.<br /> This learning group was created by <strong>{0}</strong>.",
      "joinnow": "Join now",
      "joinregistration": "Join Registration",
      "ratingintro": "To sign up you must rate the following topics, dates or events with 1-5 stars. The rating below was given by the founder of the learning group and applies to all participants",
      "state": "The application is currently in status:"
    },
    "labels": {
      "gotooffer": "Go to offer",
      "gotooffering": "To the offer list",
      "mandatory": "Mandatory",
      "namematno": "Name and matriculation id",
      "optional": "Optional",
      "productterm": "Degree program and semester (in the event semester)",
      "usergroup": "User group"
    },
    "texts": {
      "assigned": "assigned",
      "masterdataintro": "The following information is automatically provided to the organizer. \nBy registering, you agree that this data will be stored for the purpose of registration and passed on to the organizer.",
      "noowner": "Missing owner",
      "ratingcountsintro": "You must submit at least the following ratings",
      "ratingcountsmax": "max. {0}x (currently: {1})",
      "ratingcountsmin": "min. {0}x (remaining{1})",
      "ratingcountsminmax": "{0}-{1}x (currently: {2})",
      "ratingcountsminmaxsame": "exactly {0}x (currently: {1})",
      "ratingcountsminok": "min. {0}x",
      "ratingcountsnone": "no rating allowed (currently: {0})",
      "ratingcountsoverflow": "Ratings beyond the required limits are used for meeting lower requirements.",
      "ratingcountsremaining": "{0} missing",
      "ratingincomplete": "Your ratings are incomplete or do not fulfil all requirements.",
      "ratingintro": "In order to register, you must rate the following topics, appointments or events with 1-5 stars.",
      "ratingrequireallrated": "All topics/appointments/events must be rated",
      "ratingrequireaverage": "Average rating needs to be  <strong>at least \n{0} stars</strong> (currently: {1})",
      "ratingrequireequalpct": "<strong>A maximum of {0}%</strong> of the topics may be rated the same (currently: {1}%)",
      "ratingrequirementintro": "Your ratings must meet the following requirements",
      "requestedinformationincomplete": "Not all mandatory information has been filled out.",
      "selectionincomplete": "A topic, appointment or event must be selected.",
      "selectionintro": "To register, you must select exactly one topic or date or event in which you are interested from the list below. During the allocation of places, a decision will be made on the basis of your selection as to whether to offer or accept a place.",
      "selectionwaitlist": "The waiting list for already filled topics or dates is activated. You can register for a topic, appointment or event on which all seats are already filled and receive a confirmation when a seat becomes available there. You will see the total number of registered persons (including the waiting list).",
      "studygroupmemberintro": "You are a member of a study group for this application. \nThe registration can only be modified by the founder of the study group ({0})."
    }
  },
  "stats": {
    "titles": {
      "deadlineCounts": "Current outstanding confirmation deadlines",
      "degreeCounts": "Distribution of applications to degrees",
      "productCounts": "Distribution of applications among study programs",
      "registrationTimeCounts": "Receipt of registrations",
      "stateCounts": "Distribution of the registration state",
      "topProcedures": "Procedures with the most applications"
    }
  },
  "texts": {
    "createnewprocedure": "Neues Anmeldeverfahren anlegen",
    "createnewtemplate": "Create new template",
    "editprocedure": "Settings",
    "exportilias": {
      "noAccess": "You are not authorized to access the ILIAS course",
      "noCampusEvent": "There is no campus event linked",
      "noIliasCourse": "There is no ILIAS course for the specified campus event"
    },
    "group": {
      "create": "Create group"
    },
    "groups": {
      "access": "Manage group access",
      "edit": "Edit group"
    },
    "personselectstudenthint": "You can select students using their full matriculation number or their u-account",
    "perterm": "per semester",
    "topics": {
      "assistant": "Appointment assistant",
      "editevent": "Edit event",
      "editsingleappointment": "Edit single appointment",
      "edittopic": "Edit topic",
      "editweeklyappointment": "Edit weekly appointment",
      "newevent": "Add event",
      "newsingleappointment": "Add new single appoinment",
      "newtopic": "Add new topic",
      "newweeklyappointment": "Add weekly appointment"
    }
  }
}
